import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhoAreWe from "../../components/who-are-we"
import Sponsores from "../../components/sponsores"
import Realisation from "../../components/realisation"

class TacosDeLyon extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Tacos De Lyon" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-tacosdelyon inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center"></h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">
                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://tacos.ma/" title="" target="_blank">
                                <img src="/img/clients/tacos-icons-app.png" alt="" className="project__logo" />
                            </a>
                        </p>
                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">TACOS DE LYON.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://tacos.ma/" title="PRECISION SYSTEMS" target="_blank">tacos.ma</a>
                            </span>
                            <span className="project__detail">Year 2016</span>
                        </p>
                    </aside>
                    <p className="v-center">
                        <h1 className="article__heading">Tacos de Lyon : Plateforme de commande en ligne.</h1>
                        <p className="article__para">
                            Le sandwich parfait existe, et il est à portée de clics. Scrollez, sélectionnez, modifiez, validez... Laissez vos envies s’exprimer, vous voilà avec le tacos idéal ! Simple d’utilisation, ergonomique et intuitive, la plateforme permet à l’utilisateur de personnaliser ses sandwiches à l’infini.
                        </p>
                    </p>
                </div>
                
                <hr style={{ backgroundColor: "white", height: "1px", borderTop: "1px solid white"}} />

                <p className="article__tacos">
                    <img className="article__belt-image" src="/img/projects/tacosdelyon/experience.jpg" alt="branding" />
                </p>
                
                <hr style={{ backgroundColor: "white", height: "1px", borderTop: "1px solid white"}} />
                
                <br />

            </article>
            <Realisation/>
            <Sponsores />
            <WhoAreWe />
        </Layout>
    
  )

}

export default TacosDeLyon
